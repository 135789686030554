import { faDownload, faExclamationTriangle, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import FileSaver from "file-saver";
import React from "react";
import Button from "react-bootstrap/Button";
import PulseLoader from "react-spinners/PulseLoader";
import xml2js from "xml2js";
import "../styles.css";
import Progress from "./Progress";

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      creatingStatus: "Creating File",
      dataStatus: "Reading Data",
      error: null,
      file: null,
      parsedNavData: null,
    };
  }

  calculateNext() {
    var inputs = document.getElementsByClassName("create-input");
    this.setState({ next: 1 });

    Array.prototype.forEach.call(
      inputs,
      function (e) {
        if (e.value.length === 0) {
          this.setState({ next: 0 });
        }
      }.bind(this)
    );
  }

  parseData() {
    this.setState({ step: 3 });
    var navReader = new FileReader();
    var aptReader = new FileReader();
    var fixReader = new FileReader();
    var awyReader = new FileReader();
    var filesLoaded = 0;
    var filesToLoad = 4;
    navReader.onload = function () {
      filesLoaded++;
      if (filesToLoad === filesLoaded) {
        const worker = new Worker("Workers/ParseData.js");
        worker.postMessage({
          navdata: navReader.result,
          aptdata: aptReader.result,
          fixdata: fixReader.result,
          awydata: awyReader.result,
          lat: document.getElementById("data-lat").value,
          long: document.getElementById("data-long").value,
          range: document.getElementById("data-range").value,
        });
        worker.onmessage = function (e) {
          if (e.data.isStatus) this.setState({ dataStatus: e.data.message });
          else this.setState({ step: 4, parsedNavData: e.data.data });
        }.bind(this);
      }
    }.bind(this);
    aptReader.onload = function () {
      filesLoaded++;
      if (filesToLoad === filesLoaded) {
        const worker = new Worker("Workers/ParseData.js");
        worker.postMessage({
          navdata: navReader.result,
          aptdata: aptReader.result,
          fixdata: fixReader.result,
          awydata: awyReader.result,
          lat: document.getElementById("data-lat").value,
          long: document.getElementById("data-long").value,
          range: document.getElementById("data-range").value,
        });
        worker.onmessage = function (e) {
          if (e.data.isStatus) this.setState({ dataStatus: e.data.message });
          else this.setState({ step: 4, parsedNavData: e.data.data });
        }.bind(this);
      }
    }.bind(this);
    fixReader.onload = function () {
      filesLoaded++;
      if (filesToLoad === filesLoaded) {
        const worker = new Worker("Workers/ParseData.js");
        worker.postMessage({
          navdata: navReader.result,
          aptdata: aptReader.result,
          fixdata: fixReader.result,
          awydata: awyReader.result,
          lat: document.getElementById("data-lat").value,
          long: document.getElementById("data-long").value,
          range: document.getElementById("data-range").value,
        });
        worker.onmessage = function (e) {
          if (e.data.isStatus) this.setState({ dataStatus: e.data.message });
          else this.setState({ step: 4, parsedNavData: e.data.data });
        }.bind(this);
      }
    }.bind(this);
    awyReader.onload = function () {
      filesLoaded++;
      if (filesToLoad === filesLoaded) {
        const worker = new Worker("Workers/ParseData.js");
        worker.postMessage({
          navdata: navReader.result,
          aptdata: aptReader.result,
          fixdata: fixReader.result,
          awydata: awyReader.result,
          lat: document.getElementById("data-lat").value,
          long: document.getElementById("data-long").value,
          range: document.getElementById("data-range").value,
        });
        worker.onmessage = function (e) {
          if (e.data.isStatus) this.setState({ dataStatus: e.data.message });
          else this.setState({ step: 4, parsedNavData: e.data.data });
        }.bind(this);
      }
    }.bind(this);
    if (document.getElementById("nav-file").files.length !== 0) navReader.readAsText(document.getElementById("nav-file").files[0]);
    else filesToLoad--;
    if (document.getElementById("apt-file").files.length !== 0) aptReader.readAsText(document.getElementById("apt-file").files[0]);
    else filesToLoad--;
    if (document.getElementById("fix-file").files.length !== 0) fixReader.readAsText(document.getElementById("fix-file").files[0]);
    else filesToLoad--;
    if (document.getElementById("awy-file").files.length !== 0) awyReader.readAsText(document.getElementById("awy-file").files[0]);
    else filesToLoad--;
    if (
      filesToLoad === 0 ||
      !document.getElementById("data-lat").value ||
      !document.getElementById("data-long").value ||
      !document.getElementById("data-range").value
    )
      this.setState({ step: 4 });
  }

  create() {
    this.setState({ step: 6 });
    const worker = new Worker("Workers/CreateSct.js");
    let parser = new xml2js.Parser();
    var xml;
    parser.parseString(document.getElementById("kml").value, function (err, result) {
      xml = result;
    });

    worker.postMessage({
      name: document.getElementById("name").value,
      callsign: document.getElementById("callsign").value,
      nmlat: document.getElementById("nmlat").value,
      nmlong: document.getElementById("nmlong").value,
      airport: document.getElementById("airport").value,
      mv: document.getElementById("mv").value,
      lat: document.getElementById("lat").value,
      long: document.getElementById("long").value,
      scale: document.getElementById("scale").value,
      kml: xml,
      mapColor: document.getElementById("map-color").value,
      labelColor: document.getElementById("label-color").value,
      navData: this.state.parsedNavData,
    });
    worker.onmessage = function (e) {
      if (e.data.isError) {
        this.setState({ step: 4, error: e.data.error });
      } else if (e.data.isStatus) {
        this.setState({ creatingStatus: e.data.status });
      } else {
        var sct = new Blob([e.data.sct], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(sct, "sector.sct2");
        this.setState({ step: 7 });
      }
    }.bind(this);
  }

  render() {
    return (
      <div className="page-container">
        <Progress steps={7} step={this.state.step} />
        <div className={this.state.step === 1 ? "step-container" : "step-container hidden"}>
          <h3>Enter sector information:</h3>
          <br />
          <table className="input-table">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="name">Sector File Name: </label>
                </td>
                <td>
                  <input id="name" placeholder="Boston Tower v5.0" className="create-input" onChange={() => this.calculateNext()} />
                </td>
                <td> </td>
                <td>
                  <label htmlFor="nmlat">Nautical Miles per Degree of Latitude:</label>
                </td>
                <td>
                  <input id="nmlat" defaultValue="60" className="create-input" onChange={() => this.calculateNext()} />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="callsign">Default Callsign:</label>
                </td>
                <td>
                  <input id="callsign" placeholder="BOS_TWR" className="create-input" onChange={() => this.calculateNext()} />
                </td>
                <td> </td>
                <td>
                  <label htmlFor="nmlong">Nautical Miles per Degree of Longitude:</label>
                </td>
                <td>
                  <input id="nmlong" placeholder="45" className="create-input" onChange={() => this.calculateNext()} />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="airport">Default Airport:</label>
                </td>
                <td>
                  <input id="airport" placeholder="KBOS" className="create-input" onChange={() => this.calculateNext()} />
                </td>
                <td> </td>
                <td>
                  <label htmlFor="mv">Magnetic Variation:</label>
                </td>
                <td>
                  <input id="mv" placeholder="16" className="create-input" onChange={() => this.calculateNext()} />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="lat">Default Latitude:</label>
                </td>
                <td>
                  <input id="lat" placeholder="42.717" className="create-input" onChange={() => this.calculateNext()} />
                </td>
                <td className="table-spacer"></td>
                <td>
                  <label htmlFor="scale">Sector Scale Value:</label>
                </td>
                <td>
                  <input id="scale" defaultValue="1" className="create-input" onChange={() => this.calculateNext()} />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="long">Default Longitude:</label>
                </td>
                <td>
                  <input id="long" placeholder="-71.124" className="create-input" onChange={() => this.calculateNext()} />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <Button className="next-button" variant="success" onClick={() => this.setState({ step: 2 })}>
            Next
          </Button>
        </div>

        <div className={this.state.step === 2 ? "step-container" : "step-container hidden"}>
          <h3>Optionally, upload FAA NASR data:</h3>
          <div>
            This utility can generate sector file navigation data from supplied FAA data. To download updated data, navigate to the FAA's{" "}
            <a rel="noreferrer" target="_blank" href="https://www.faa.gov/air_traffic/flight_info/aeronav/aero_data/NASR_Subscription/">
              28 Day NASR Subscription
            </a>
            , click on the current period, and download the .zip file. After unzipping the file, upload the following files:
          </div>
          <br />
          <table className="input-table">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="apt-file">APT.txt: </label>
                </td>
                <td>
                  <input type="file" id="apt-file" accept=".txt" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="awy-file">AWY.txt: </label>
                </td>
                <td>
                  <input type="file" id="awy-file" accept=".txt" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="fix-file">FIX.txt: </label>
                </td>
                <td>
                  <input type="file" id="fix-file" accept=".txt" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="nav-file">NAV.txt: </label>
                </td>
                <td>
                  <input type="file" id="nav-file" accept=".txt" />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          Include data within <input placeholder="350" id="data-range" /> NM of <input placeholder="43.2198" id="data-lat" />{" "}
          <input placeholder="-71.5754" id="data-long" />
          <Button className="next-button" variant="success" onClick={() => this.parseData()}>
            Next
          </Button>
        </div>
        <div className={this.state.step === 3 ? "step-container" : "step-container hidden"}>
          <div className="status">
            <i>{this.state.dataStatus}</i>
            <PulseLoader
              color="#777777"
              css={`
                margin-left: 10px;
              `}
              size={10}
            />
          </div>
        </div>
        <div className={this.state.step === 4 ? "step-container" : "step-container hidden"}>
          <h3>Upload KML:</h3>
          <br />

          <div className="kml-info">
            <div>
              To create the ARTCC boundary, SID, STAR, geography, regions and labels sections you will need to download and install&nbsp;
              <a href="https://www.google.com/earth/versions/#earth-pro" target="_blank" rel="noreferrer">
                Google Earth
              </a>
              .
            </div>
            <div>
              Then create the following file structure (click{" "}
              <a href="example.kml" download="example.kml">
                here
              </a>{" "}
              for an example):
            </div>
            <div>
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;Sector</strong>&nbsp;(any name)
            </div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;ARTCC</strong>
            </div>
            <div className="indent2">
              <FontAwesomeIcon icon={faFolder} />
              &nbsp; <strong>FIR</strong> (multiple FIR folders allowed)
            </div>
            <div className="indent3">• Paths optionally named with their color</div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;ARTCC HIGH</strong>
            </div>
            <div className="indent2">
              <FontAwesomeIcon icon={faFolder} />
              &nbsp; <strong>Sector</strong> (multiple sector folders allowed)
            </div>
            <div className="indent3">• Paths optionally named with their color</div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;ARTCC LOW</strong>
            </div>
            <div className="indent2">
              <FontAwesomeIcon icon={faFolder} />
              &nbsp; <strong>Sector</strong> (multiple sector folders allowed)
            </div>
            <div className="indent3">• Paths optionally named with their color</div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;SID</strong>
            </div>
            <div className="indent2">
              <FontAwesomeIcon icon={faFolder} />
              &nbsp; <strong>Video Map</strong> (multiple video map folders allowed)
            </div>
            <div className="indent3">• Paths optionally named with their color</div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;STAR</strong>
            </div>
            <div className="indent2">
              <FontAwesomeIcon icon={faFolder} />
              &nbsp; <strong>Video Map</strong> (multiple video map folders allowed)
            </div>
            <div className="indent3">• Paths optionally named with their color</div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;GEO</strong>
              <div className="indent1">• Paths named with their color</div>
            </div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;REGIONS</strong>
              <div className="indent1">• Polygons named with their color</div>
            </div>
            <div className="indent1">
              <FontAwesomeIcon icon={faFolder} />
              <strong>&nbsp;LABELS</strong>
              <div className="indent1">• Placemarks named with their label and an optional description of their color</div>
            </div>
            <div>To upload, simply copy the root sector folder and paste into the box on the right.</div>
          </div>
          <textarea className="kml-upload" id="kml" placeholder="Paste KML here" />
          <Button className="next-button" variant="success" onClick={() => this.setState({ step: 5 })}>
            Next
          </Button>
        </div>
        <div className={this.state.step === 5 ? "step-container" : "step-container hidden"}>
          <h3>Enter optional default colors:</h3>
          <br />
          <table className="input-table">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="map-color">Map Color: </label>
                </td>
                <td>
                  <input id="map-color" className="create-input" />
                </td>
                <td> </td>
                <td>
                  <label htmlFor="label-color">Label Color:</label>
                </td>
                <td>
                  <input id="label-color" className="create-input" />
                </td>
              </tr>
            </tbody>
          </table>
          <Button className="next-button" variant="success" onClick={() => this.create()}>
            Convert
          </Button>
        </div>
        <div className={this.state.step === 6 ? "step-container" : "step-container hidden"}>
          <div className="status">
            <i>{this.state.creatingStatus}</i>
            <PulseLoader
              color="#777777"
              css={`
                margin-left: 10px;
              `}
              size={10}
            />
          </div>
        </div>
        <div className={this.state.step === 7 ? "step-container" : "step-container hidden"}>
          <div className={this.state.error !== null ? "hidden" : "status"}>
            <FontAwesomeIcon icon={faDownload} />
            <br />
            <i>Downloading File</i>
            <PulseLoader
              color="#777777"
              css={`
                margin-left: 10px;
              `}
              size={10}
            />
            <div className="download-subtitle">
              Issues? Please send me an email at <a href="mailto:web@bvartcc.com">web@bvartcc.com</a>
            </div>
            <Button
              variant="outline-secondary"
              onClick={() => {
                this.setState({
                  step: 1,
                  next: 0,
                  creatingStatus: "Creating File",
                  error: "",
                });
              }}
            >
              Create another File
            </Button>
          </div>
          <div className={this.state.error !== null ? "status" : "hidden"}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <br />
            <i>Error Creating File</i>
            <div className="download-subtitle">
              Issues? Please send me an email at <a href="mailto:web@bvartcc.com">web@bvartcc.com</a>
            </div>
            <div className="download-subtitle">Please reference the following error message and include a copy of your Google Earth KML:</div>
            <div className="download-subtitle">
              <i>{this.state.error}</i>
            </div>
            <Button
              variant="outline-secondary"
              onClick={() => {
                this.setState({
                  step: 1,
                  next: 0,
                  creatingStatus: "Creating File",
                  error: "",
                });
              }}
            >
              Create another File
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Create;
