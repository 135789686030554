import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Create from "../Components/Create";
import GeoJson from "../Components/GeoJson";
import Header from "../Components/Header";
import Kml from "../Components/Kml";
import "../styles.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 0 };
  }

  setPage(page) {
    this.setState({ page: page });
  }

  render() {
    var activeWindow = <div />;
    switch (this.state.page) {
      case 0:
        activeWindow = <Create />;
        break;
      case 1:
        activeWindow = <Kml />;
        break;
      case 2:
        activeWindow = <GeoJson />;
        break;

      default:
        break;
    }
    return (
      <div>
        <Header page={this.state.page} setPage={this.setPage.bind(this)} />
        {activeWindow}
      </div>
    );
  }
}

export default App;
